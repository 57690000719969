import { Theme } from '@mui/material/styles';

export default function TreeItem(theme: Theme) {
  return {
    MuiTreeItem: {
      styleOverrides: {
        content: {
          padding: '3px 4px',
          gap: 0,
          cursor: 'initial',
          '&:hover': {
            backgroundColor: 'unset',
          },
          '&.Mui-selected': {
            backgroundColor: 'unset',
          },
          '&.Mui-selected.Mui-focused': {
            backgroundColor: 'unset',
          },
          '&.Mui-selected:hover': {
            backgroundColor: 'unset',
          },
        },
        iconContainer: {
          width: '26px',
        },
        label: {
          ...theme.typography.bodyRegular,
          color: theme.palette.text.secondary,
          '&:hover': {
            cursor: 'pointer',
            color: `${theme.palette.text.primary}`,
            backgroundColor: theme.palette.secondary.lighter,
          },
          borderRadius: '6px',
          paddingTop: '4px',
          paddingBottom: '4px',
          paddingLeft: '6px',
          paddingRight: '24px',
        },
      },
    },
  };
}
