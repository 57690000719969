import { ReactRenderer } from '@tiptap/react';
import type { SuggestionOptions } from '@tiptap/suggestion';
import tippy, { type Instance as TippyInstance } from 'tippy.js';
import { MentionList, SuggestionListRef } from './mentionlist';
import { FlatSubstitutions, SuggestionItem } from './types';

const DOM_RECT_FALLBACK: DOMRect = {
  bottom: 0,
  height: 0,
  left: 0,
  right: 0,
  top: 0,
  width: 0,
  x: 0,
  y: 0,
  toJSON() {
    return {};
  },
};

export const makeSuggestionOptions = (
  substitutions?: FlatSubstitutions,
): Omit<SuggestionOptions<SuggestionItem>, 'editor'> => {
  return {
    items: ({ query }) => {
      return Object.keys(substitutions ?? [])
        .map(item => ({ id: item, label: item }))
        .filter(item => item.label.toLowerCase().startsWith(query.toLowerCase()));
    },

    render: () => {
      let component: ReactRenderer<SuggestionListRef>;
      let popup: TippyInstance;

      return {
        onStart: props => {
          component = new ReactRenderer(MentionList, {
            props,
            editor: props.editor,
          });

          popup = tippy('body', {
            getReferenceClientRect: () => props.clientRect?.() ?? DOM_RECT_FALLBACK,
            appendTo: () => document.body,
            content: component.element,
            showOnCreate: true,
            interactive: true,
            trigger: 'manual',
            placement: 'bottom-start',
          })[0];
        },

        onUpdate(props) {
          component.updateProps(props);

          popup.setProps({
            getReferenceClientRect: () => props.clientRect?.() ?? DOM_RECT_FALLBACK,
          });
        },

        onKeyDown(props) {
          if (props.event.key === 'Escape') {
            popup.hide();
            return true;
          }

          return component.ref?.onKeyDown?.(props) ?? false;
        },

        onExit() {
          popup?.destroy();
          component?.destroy();
        },
      };
    },
  };
};
