import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import Banner from '@assets/banner.svg?react';

export interface LayoutPreauthProps {
  children: React.ReactNode;
}

export function LayoutPreauth({ children }: LayoutPreauthProps) {
  const theme = useTheme();

  const mobile = useMediaQuery(theme.breakpoints.down('mb'));

  return (
    <Stack
      sx={{
        backgroundColor: theme.palette.common.white,
        flexDirection: {
          xs: 'column',
          mb: 'row',
        },
        width: '100%',
        height: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          backgroundColor: {
            mb: theme.palette.primary[50],
          },
          width: {
            xs: '100%',
            mb: '300px',
            sm: '400px',
            md: '600px',
          },
          paddingLeft: {
            xs: 4,
            mb: 0,
          },
          paddingRight: {
            xs: 4,
            mb: 0,
          },
          paddingTop: {
            xs: '60px',
            mb: 0,
          },
          paddingBottom: {
            xs: 1,
            mb: 0,
          },
        }}
      >
        <Banner
          width={mobile ? '38px' : '100%'}
          height={mobile ? '38px' : '100%'}
        />
      </Box>
      <Stack
        direction="column"
        sx={{
          justifyContent: {
            xs: 'flex-start',
            mb: 'center',
          },
          alignItems: 'center',
          paddingLeft: 4,
          paddingRight: 4,
          backgroundColor: theme.palette.common.white,
          flexGrow: 1,
        }}
      >
        {children}
      </Stack>
    </Stack>
  );
}
