export default function DateTimePickerToolbar() {
  return {
    MuiDateTimePickerToolbar: {
      styleOverrides: {
        root: {
          padding: '12px',
          paddingBottom: '0px',
          '& .MuiTypography-overline': {
            display: 'none',
          },
        },
        dateContainer: {
          gap: '4px',
          flexDirection: 'row',
        },
        separator: {
          marginY: '4px',
        },
        timeDigitsContainer: {
          flexDirection: 'row',
          alignItems: 'center',
        },
      },
    },
  };
}
