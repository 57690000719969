import { Theme } from '@mui/material/styles';

export default function Divider(theme: Theme) {
  const { palette } = theme;

  return {
    MuiDivider: {
      variants: [
        {
          props: { variant: 'dashed' },
          style: {
            borderStyle: 'dashed',
          },
        },
      ],
      styleOverrides: {
        root: {
          color: palette.secondary.main,
          borderColor: palette.divider,
        },
        withChildren: {
          '&::before, &::after': {
            borderColor: palette.divider,
          },
        },
      },
    },
  };
}
