import { FieldPolicy } from '@apollo/client';
import Decimal from 'decimal.js';
import isNil from 'lodash/isNil';

export const decimalTypePolicy: FieldPolicy<Decimal> = {
  merge: (_, incoming) => {
    if (isNil(incoming)) {
      return incoming;
    }
    if (incoming instanceof Decimal) {
      return incoming;
    }
    return new Decimal(incoming);
  },
};
