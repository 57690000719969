import { Theme } from '@mui/material/styles';

export default function Alert(theme: Theme) {
  return {
    MuiAlert: {
      styleOverrides: {
        root: {
          ...theme.typography.bodyRegular,
          backgroundColor: theme.palette.common.black,
          borderRadius: theme.shape.borderRadius,
          padding: '12px 16px',
          color: theme.palette.common.white,
        },
        icon: {
          fontSize: '16px',
          padding: '2px',
          marginRight: '6px',
          alignSelf: 'center',
        },
        action: {
          paddingLeft: '12px',
          paddingRight: 0,
          paddingTop: 0,
          paddingBottom: 0,
          justifyContent: 'center',
          alignItems: 'center',
          alignSelf: 'center',
        },
        message: {
          alignSelf: 'center',
          padding: 0,
        },
        filledSuccess: {
          '& .MuiAlert-icon': {
            color: theme.palette.success.main,
          },
        },
        filledInfo: {
          '& .MuiAlert-icon': {
            color: theme.palette.primary.main,
          },
        },
        filledWarning: {
          '& .MuiAlert-icon': {
            color: theme.palette.warning.main,
          },
        },
        filledError: {
          '& .MuiAlert-icon': {
            color: theme.palette.error.main,
          },
        },
      },
    },
  };
}
