import type { Theme } from '@mui/material/styles';

export default function Autocomplete(theme: Theme) {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            color: theme.palette.grey[300], // chevron color
          },
        },
        input: {
          padding: '7px 12px 7px 12px !important',

          '&.MuiInputBase-inputAdornedStart': {
            padding: '7px 12px 7px 8px !important',
          },
        },
        inputRoot: {
          padding: '0px 0px 0px 0px',

          '&.MuiInputBase-adornedStart': {
            paddingLeft: '12px',
          },

          '&.MuiInputBase-adornedEnd': {
            paddingRight: '14px',
          },

          color: theme.palette.grey[700],
          '&::placeholder': {
            color: theme.palette.grey[300],
          },
        },
        popupIndicator: {
          width: 'auto',
          height: 'auto',
        },
        clearIndicator: {
          width: 'auto',
          height: 'auto',
          visibility: 'visible',
        },
        paper: {
          borderRadius: 4,
          margin: '4px 0',
          padding: '4px 0',
          boxShadow: theme.customShadows.popover,
        },
        noOptions: {
          ...theme.typography.bodyRegular,
          padding: '7px 12px',
        },
        loading: {
          ...theme.typography.bodyRegular,
          padding: '7px 12px',
        },
        option: {
          padding: '7px 12px !important',
          minHeight: '0px !important',

          '&:hover': {
            backgroundColor: `${theme.palette.secondary[100]} !important`,
          },

          '&[aria-selected="true"]': {
            backgroundColor: `${theme.palette.grey.A800} !important`,
          },
        },
        listbox: {
          padding: 0,
          minHeight: '0px !important',
          maxHeight: '305px !important',
        },
        tag: {
          margin: '3px 2px',
          '&:first-of-type': {
            marginLeft: '8px',
          },
        },
      },
    },
  };
}
