import {
  Alert,
  type AlertColor,
  IconButton,
  IconButtonProps,
  Stack,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { SnackbarContent as NotiSnackbarContent, type SnackbarOrigin, closeSnackbar, enqueueSnackbar } from 'notistack';
import { useCallback, useMemo } from 'react';
import { CloseIcon, ErrorIcon, InfoIcon, SuccessIcon, WarningIcon } from '@components/actionicons';

const SnackbarContent = styled(NotiSnackbarContent)({
  display: 'flex',
  justifyContent: 'end',
});

function CloseButton(props: Pick<IconButtonProps, 'onClick'>) {
  return (
    <IconButton
      size="small"
      sx={{
        height: 20,
        width: 20,
      }}
      {...props}
    >
      <CloseIcon
        color="white"
        height={12}
        width={12}
      />
    </IconButton>
  );
}

function useAnchorOrigin(): SnackbarOrigin {
  const theme = useTheme();

  const mobile = useMediaQuery(theme.breakpoints.down('mb'));

  return useMemo(() => {
    if (mobile) {
      return {
        vertical: 'top',
        horizontal: 'center',
      };
    }

    return {
      vertical: 'bottom',
      horizontal: 'right',
    };
  }, [mobile]);
}

type NotificationType = 'success' | 'info' | 'warning' | 'error';

interface EnqueueOptions {
  variant: NotificationType;
  message: string;
  icon: React.ReactNode;
  autoHideDuration?: number | null;
  action?: React.ReactElement;
}

function useEnqueue() {
  const anchorOrigin = useAnchorOrigin();

  return useCallback(
    ({ icon, message, variant, autoHideDuration, action }: EnqueueOptions) => {
      enqueueSnackbar(message, {
        variant,
        preventDuplicate: true,
        autoHideDuration,
        disableWindowBlurListener: true,
        anchorOrigin,
        content: key => (
          <SnackbarContent>
            <Alert
              icon={icon}
              severity={variant as AlertColor}
              variant="filled"
              action={
                <Stack
                  direction="row"
                  alignItems="center"
                >
                  {action}
                  <CloseButton onClick={() => closeSnackbar(key)} />
                </Stack>
              }
            >
              {message}
            </Alert>
          </SnackbarContent>
        ),
      });
    },
    [anchorOrigin],
  );
}

export type Notification = (message: string, action?: React.ReactElement) => void;

export type UseNotificationResult = {
  success: Notification;
  info: Notification;
  warning: Notification;
  error: Notification;
};

const icons: Record<NotificationType, React.ReactNode> = {
  success: <SuccessIcon />,
  info: <InfoIcon />,
  warning: <WarningIcon />,
  error: <ErrorIcon />,
};

function useMakeNotification(autoHideDuration?: number | null) {
  const enqueue = useEnqueue();

  return useCallback(
    (variant: NotificationType) => {
      return function notify(message: string, action?: React.ReactElement) {
        enqueue({
          message,
          variant,
          autoHideDuration,
          icon: icons[variant],
          action,
        });
      };
    },
    [autoHideDuration, enqueue],
  );
}

export function useNotification(autoHideDuration?: number | null): UseNotificationResult {
  const makeNotification = useMakeNotification(autoHideDuration);

  return useMemo(
    () => ({
      success: makeNotification('success'),
      info: makeNotification('info'),
      warning: makeNotification('warning'),
      error: makeNotification('error'),
    }),
    [makeNotification],
  );
}
