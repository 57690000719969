import { Typography } from '@mui/material';
import type { SuggestionOptions, SuggestionProps } from '@tiptap/suggestion';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem, MenuList, NoResultsWrapper } from './styled';
import { SuggestionItem } from './types';

export type SuggestionListRef = {
  onKeyDown: NonNullable<ReturnType<NonNullable<SuggestionOptions<string>['render']>>['onKeyDown']>;
};

export type SuggestionListProps = SuggestionProps<SuggestionItem>;

export const MentionList = forwardRef<SuggestionListRef, SuggestionListProps>(({ items, command }, ref) => {
  const { t } = useTranslation();

  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectItem = useCallback(
    (index: number) => {
      const item = items[index];

      if (item) {
        command(item);
      }
    },
    [command, items],
  );

  const upHandler = useCallback(() => {
    setSelectedIndex((selectedIndex + items.length - 1) % items.length);
  }, [items.length, selectedIndex]);

  const downHandler = useCallback(() => {
    setSelectedIndex((selectedIndex + 1) % items.length);
  }, [items.length, selectedIndex]);

  const enterHandler = useCallback(() => {
    selectItem(selectedIndex);
  }, [selectItem, selectedIndex]);

  useEffect(() => setSelectedIndex(0), [items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === 'ArrowUp') {
        upHandler();
        return true;
      }

      if (event.key === 'ArrowDown') {
        downHandler();
        return true;
      }

      if (event.key === 'Enter') {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  return (
    <MenuList>
      {items.length ? (
        items.map((item, index) => (
          <MenuItem
            key={item.id}
            dense
            selected={selectedIndex === index}
            onClick={() => selectItem(index)}
          >
            <Typography
              variant="bodyBold"
              sx={{
                color: 'text.primary',
              }}
            >
              {item.label}
            </Typography>
          </MenuItem>
        ))
      ) : (
        <NoResultsWrapper
          direction="row"
          justifyContent="center"
        >
          <Typography
            variant="bodyBold"
            sx={{
              color: 'text.primary',
            }}
          >
            {t('error/substitutions')}
          </Typography>
        </NoResultsWrapper>
      )}
    </MenuList>
  );
});
