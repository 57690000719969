import { BroadcastChannel } from 'broadcast-channel';
import { useEffect, useMemo } from 'react';

export type { BroadcastChannel };

export enum MessageType {
  SYNCHRONISE = 'synchronise',
  AUTHENTICATED = 'authenticated',
  REAUTHENTICATE = 'reauthenticate',
  LOGOUT = 'logout',
  LOGGED_OUT = 'logged_out',
  PING = 'ping',
  PONG = 'pong',
}

export interface SynchroniseMessage {
  type: MessageType.SYNCHRONISE;
}

export interface AuthenticatedMessage {
  type: MessageType.AUTHENTICATED;
  userId: string;
}

export interface ReauthenticateMessage {
  type: MessageType.REAUTHENTICATE;
}

export interface LogOutMessage {
  type: MessageType.LOGOUT;
}

export interface LoggedOutMessage {
  type: MessageType.LOGGED_OUT;
}

export interface PingMessage {
  type: MessageType.PING;
}

export interface PongMessage {
  type: MessageType.PONG;
}

export type Message =
  | AuthenticatedMessage
  | SynchroniseMessage
  | ReauthenticateMessage
  | LogOutMessage
  | LoggedOutMessage
  | PingMessage
  | PongMessage;

export function createChannel() {
  return new BroadcastChannel<Message>('antaeus-sw');
}

export interface UseMessage {
  send: (msg: Message) => Promise<void>;
}

export function useMessage(channel: BroadcastChannel<Message>, handler: (data: Message) => void): UseMessage {
  useEffect(() => {
    channel.addEventListener('message', handler);

    return () => {
      channel.removeEventListener('message', handler);
    };
  }, [handler, channel]);

  return useMemo(
    () => ({
      async send(msg: Message) {
        channel.postMessage(msg);
      },
    }),
    [channel],
  );
}
