import { Stack, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import { LayoutPreauth } from '@features/ng/layout';

export function LoadingScreen() {
  const { t } = useTranslation();

  return (
    <LayoutPreauth>
      <Stack
        direction="column"
        spacing={1}
        sx={{
          justifyItems: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress color="primary" />
        <Typography variant="bodyBold">{t('general/loading')}</Typography>
      </Stack>
    </LayoutPreauth>
  );
}
