import { FieldPolicy } from '@apollo/client';
import isNil from 'lodash/isNil';

export const dateTypePolicy: FieldPolicy<Date> = {
  merge: (_, incoming) => {
    if (isNil(incoming)) {
      return incoming;
    }
    if (incoming instanceof Date) {
      return incoming;
    }

    return new Date(incoming);
  },
};
