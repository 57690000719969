import { Theme } from '@mui/material/styles';

export default function LinearProgress(theme: Theme) {
  return {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: 6,
          borderRadius: 100,
          backgroundColor: theme.palette.secondary.lighter,
        },
        bar: {
          borderRadius: 100,
        },
      },
    },
  };
}
