import { useLazyQuery, useMutation } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import type { User } from '@api/deianira/generated/graphql';
import { GET_USER_PROFILE, SWITCH_USER } from './queries/users';

export function useUserProfile(userId?: string) {
  const [user, setUser] = useState<User | null>(null);

  const [getUserProfile, { error, loading, called }] = useLazyQuery(GET_USER_PROFILE, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      setUser(data.userProfile);
    },
  });

  useEffect(() => {
    if (userId) {
      getUserProfile();
    } else {
      setUser(null);
    }
  }, [userId, getUserProfile]);

  return [user, { error, loading, called }] as const;
}

export function useSwitchUser(onCompleted: () => void) {
  const [switchUser] = useMutation(SWITCH_USER, {
    onCompleted,
  });

  return useCallback(
    (id: string) =>
      switchUser({
        variables: {
          user: {
            userId: id,
          },
        },
      }),
    [switchUser],
  );
}
