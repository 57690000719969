export default function TimeClock() {
  return {
    MuiTimeClock: {
      styleOverrides: {
        root: {
          width: '280px',
          marginTop: '12px',
        },
        arrowSwitcher: {
          display: 'none',
        },
      },
    },
  };
}
