import CheckboxCheckedIcon from '@mantis/icons/checkbox-checked.svg?react';
import CheckboxUncheckedIcon from '@mantis/icons/checkbox-unchecked.svg?react';
import { ExtendedStyleProps } from '@mantis/types/extended';
import getColors from '@mantis/utils/getColors';
import { Theme } from '@mui/material/styles';

function getColorStyle({ color, theme }: ExtendedStyleProps) {
  const colors = getColors(theme, color);
  const { dark } = colors;

  return {
    '&.Mui-checked': {
      backgroundColor: 'transparent',
      '&:hover': {
        color: dark,
      },
      ...(color === 'swartz' && {
        'svg > g > rect': {
          stroke: 'inherit',
        },
      }),
    },
    ...(color === 'swartz' && {
      'svg > g > rect': {
        stroke: dark,
      },
    }),
  };
}

export default function Checkbox(theme: Theme) {
  const { palette } = theme;

  return {
    MuiCheckbox: {
      defaultProps: {
        icon: <CheckboxUncheckedIcon />,
        checkedIcon: <CheckboxCheckedIcon />,
      },
      styleOverrides: {
        root: {
          padding: 0,
          color: palette.secondary[300],

          'svg[font-size="small"]': {
            height: '16px',
            width: '16px',
          },

          'svg[font-size="medium"]': {
            height: '21px',
            width: '21px',
          },

          'svg[font-size="large"]': {
            height: '32px',
            width: '32px',
          },
        },
        colorPrimary: getColorStyle({ color: 'primary', theme }),
        colorSecondary: getColorStyle({ color: 'secondary', theme }),
        colorSuccess: getColorStyle({ color: 'success', theme }),
        colorWarning: getColorStyle({ color: 'warning', theme }),
        colorInfo: getColorStyle({ color: 'info', theme }),
        colorError: getColorStyle({ color: 'error', theme }),
        colorSwartz: getColorStyle({ color: 'swartz', theme }),
      },
    },
  };
}
