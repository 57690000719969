import { useNavigate } from '@router';
import { useTranslation } from 'react-i18next';
import { Outlet, useRouteError } from 'react-router-dom';
import { LoadingBackdrop } from '@components/ng/loadingstate';
import { LoadingScreen } from '@features/loading/screen';
import { FullPageContentSkeleton, ReadOnlyBarSkeleton, SideMenuSkeleton } from '@features/ng/awaitedcontent';
import { LayoutContent } from '@features/ng/layout';

export function Catch() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const error = useRouteError();

  return (
    <LoadingBackdrop
      variant="temporaryFailure"
      displayText={`An error occurred: ${error instanceof Error ? error.message : ''}`}
      retryText={t('action/retry')}
      onRetry={() => {
        navigate('/dashboard/:zoneId?', {
          params: {},
        });
      }}
      opacity={0}
    >
      <LayoutContent
        readOnlyBar={<ReadOnlyBarSkeleton />}
        sideMenu={<SideMenuSkeleton />}
        content={<FullPageContentSkeleton />}
      />
    </LoadingBackdrop>
  );
}

export const Loading = LoadingScreen;

export default function App() {
  return <Outlet />;
}
