export default function List() {
  return {
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
  };
}
