import { ShadowProps } from '@mantis/types/theme';
import { Palette, alpha } from '@mui/material/styles';

const makeCustomShadows = (palette: Palette): ShadowProps => ({
  modal:
    '0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12)',
  popover:
    '0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)',
  tab: '0px -20px 28px -10px rgba(0, 0, 0, 0.05), -20px -10px 28px -4px rgba(0, 0, 0, 0.05), 20px -10px 28px -4px rgba(0, 0, 0, 0.05)',
  button: `0 2px #0000000b`,
  text: `0 -1px 0 rgb(0 0 0 / 12%)`,
  z1: `0px 1px 4px ${alpha(palette.grey[900], 0.08)}`,
  primary: `0 0 0 2px ${alpha(palette.primary.main, 0.2)}`,
  secondary: `0 0 0 2px ${alpha(palette.secondary.main, 0.2)}`,
  error: `0 0 0 2px ${alpha(palette.error.main, 0.2)}`,
  warning: `0 0 0 2px ${alpha(palette.warning.main, 0.2)}`,
  info: `0 0 0 2px ${alpha(palette.info.main, 0.2)}`,
  success: `0 0 0 2px ${alpha(palette.success.main, 0.2)}`,
  grey: `0 0 0 2px ${alpha(palette.grey[500], 0.2)}`,
  primaryButton: `0 14px 12px ${alpha(palette.primary.main, 0.2)}`,
  secondaryButton: `0 14px 12px ${alpha(palette.secondary.main, 0.2)}`,
  errorButton: `0 14px 12px ${alpha(palette.error.main, 0.2)}`,
  warningButton: `0 14px 12px ${alpha(palette.warning.main, 0.2)}`,
  infoButton: `0 14px 12px ${alpha(palette.info.main, 0.2)}`,
  successButton: `0 14px 12px ${alpha(palette.success.main, 0.2)}`,
  greyButton: `0 14px 12px ${alpha(palette.grey[500], 0.2)}`,
});

export default makeCustomShadows;
