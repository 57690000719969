import Mention from '@tiptap/extension-mention';
import Placeholder from '@tiptap/extension-placeholder';
import Subscript from '@tiptap/extension-subscript';
import Superscript from '@tiptap/extension-superscript';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import StarterKit from '@tiptap/starter-kit';
import { useMemo } from 'react';
import AutoJoiner from 'tiptap-extension-auto-joiner';
import GlobalDragHandle from 'tiptap-extension-global-drag-handle';
import { makeSubstitutions } from './substitutions';
import { makeSuggestionOptions } from './suggestion';
import { RichTextEditorFeature, Substitutions } from './types';

export type UseDefaultExtensionsOptions = {
  features?: RichTextEditorFeature[];
  placeholder?: string;
  substitutions?: Substitutions;
};

const defaultExtensions = [
  GlobalDragHandle,
  AutoJoiner,
  StarterKit.configure({
    blockquote: false,
    code: false,
    codeBlock: false,
    heading: false,
    hardBreak: false,
    horizontalRule: false,
    bulletList: {
      keepMarks: true,
      keepAttributes: false,
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false,
    },
  }),
  Underline,
  Subscript,
  Superscript,
  TextAlign.configure({
    types: ['heading', 'paragraph'],
  }),
];

const tableExtensions = [
  Table.extend({
    draggable: false,
    selectable: true,
  }).configure({
    resizable: true,
    allowTableNodeSelection: true,
  }),
  TableRow,
  TableHeader,
  TableCell,
];

export function makeExtensions({ placeholder, features, substitutions }: UseDefaultExtensionsOptions) {
  return [
    ...defaultExtensions,
    ...(placeholder ? [Placeholder.configure({ placeholder })] : []),
    ...(features?.includes(RichTextEditorFeature.TABLES) ? tableExtensions : []),
    ...(features?.includes(RichTextEditorFeature.SUBSTITUTIONS)
      ? [
          Mention.extend({
            name: 'substitution',
          }).configure({
            HTMLAttributes: {
              class: 'mention',
            },
            suggestion: makeSuggestionOptions(makeSubstitutions(substitutions)),
            renderHTML({ node }) {
              return ['span', { class: 'mention' }, `${node.attrs.label ?? node.attrs.id}`];
            },
            renderText({ node }) {
              return `${node.attrs.label ?? node.attrs.id}`;
            },
          }),
        ]
      : []),
  ];
}

export function useExtensions({ placeholder, features, substitutions }: UseDefaultExtensionsOptions) {
  return useMemo(
    () => makeExtensions({ placeholder, features, substitutions }),
    [placeholder, features, substitutions],
  );
}
