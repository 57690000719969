import { LicenseInfo } from '@mui/x-license';
import ReactDOM from 'react-dom/client';
import config from '@config';
import App from '@app/app';

LicenseInfo.setLicenseKey(config.mui.licenseKey);

const element = document.getElementById('root');
if (element) {
  const root = ReactDOM.createRoot(element);
  root.render(<App />);
}
