import { Theme } from '@mui/material/styles';

export default function CardActions(theme: Theme) {
  return {
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: 24,
          justifyContent: 'flex-end',
          borderTop: theme.borders.default,
        },
      },
    },
  };
}
