import { Theme } from '@mui/material/styles';

export default function PickersDay(theme: Theme) {
  return {
    MuiPickersDay: {
      styleOverrides: {
        root: {
          ...theme.typography.body2Description,
          height: '26px',
          width: '26px',
          borderRadius: '6px',
          '&.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            '&:focus': {
              backgroundColor: theme.palette.primary.main,
            },
          },
          '&:hover': {
            backgroundColor: theme.palette.secondary[100],
          },
        },
        dayWithMargin: {
          margin: 0,
        },
      },
    },
  };
}
