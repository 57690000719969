import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import MantisThemeCustomisation from '@theme';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import { PropsWithChildren, createContext, useContext } from 'react';
import { I18nextProvider } from 'react-i18next';
import { IntlProvider } from 'react-intl';
import i18n from 'virtual:antaeus-i18next';
import { SnackbarProvider } from '@components/mui/snackbar';
import './styles.scss';

dayjs.extend(utc);
dayjs.extend(relativeTime);

export type StyledContextProps = PropsWithChildren<object>;

export type NonceContextT = PropsWithChildren<{
  nonce?: string;
}>;

export const NonceContext = createContext<NonceContextT>({ nonce: '' });

export const NonceContextProvider = NonceContext.Provider;

export function StyledContext({ children }: StyledContextProps) {
  const nonceContext = useContext(NonceContext);

  return (
    <MantisThemeCustomisation nonce={nonceContext.nonce}>
      <SnackbarProvider>{children}</SnackbarProvider>
    </MantisThemeCustomisation>
  );
}

export function DateContext({ children }: PropsWithChildren<object>) {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale="en-gb"
    >
      {children}
    </LocalizationProvider>
  );
}

export function IntlContext({ children }: PropsWithChildren<object>) {
  const locale = (navigator.languages && navigator.languages[0]) ?? navigator.language ?? 'en-GB';

  return (
    <IntlProvider
      locale={locale}
      defaultLocale="en-GB"
    >
      <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
    </IntlProvider>
  );
}
