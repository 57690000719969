import { Theme } from '@mui/material';

export default function ImageListItem(theme: Theme) {
  return {
    MuiImageListItem: {
      styleOverrides: {
        root: {
          position: 'relative',
          overflow: 'hidden',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            border: '2px solid rgba(0, 0, 0, 0.1)',
            boxSizing: 'border-box',
            pointerEvents: 'none',
            borderRadius: 'inherit',
          },
          '& .icon-button, & .icon': {
            position: 'absolute',
            top: '3px',
            right: '3px',
            visibility: 'hidden',
          },
          '&:hover .icon, &:hover .icon-button': {
            visibility: 'visible',
          },
          '&.active::before': {
            borderColor: theme.palette.primary.main,
          },
          '&.touch-enabled .icon-button, &.touch-enabled .icon': {
            visibility: 'visible !important',
          },
        },
      },
    },
  };
}
