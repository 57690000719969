import { ExtendedStyleProps } from '@mantis/types/extended';
import getColors from '@mantis/utils/getColors';
import getShadow from '@mantis/utils/getShadow';
import { Theme, alpha } from '@mui/material/styles';

export type ButtonVariantProps =
  | 'contained'
  | 'containedInv'
  | 'containedAlt'
  | 'light'
  | 'outlined'
  | 'dashed'
  | 'text'
  | 'textAlt'
  | 'shadow'
  | 'outlinedAlt';

interface ButtonStyleProps extends ExtendedStyleProps {
  variant: ButtonVariantProps;
}

function getColorStyle({ variant, color, theme }: ButtonStyleProps) {
  const colors = getColors(theme, color);
  const { lighter, main, dark, light, contrastText } = colors;

  const buttonShadow = `${color}Button`;
  const shadows = getShadow(theme, buttonShadow);

  const commonShadow = {
    '&::after': {
      boxShadow: `0 0 5px 5px ${alpha(main, 0.9)}`,
    },
    '&:active::after': {
      boxShadow: `0 0 0 0 ${alpha(main, 0.9)}`,
    },
    '&:focus-visible': {
      outline: `2px solid ${dark}`,
      outlineOffset: 2,
    },
  };

  switch (variant) {
    case 'contained':
      return {
        backgroundColor: main,
        '&:hover': {
          backgroundColor: color === 'swartz' ? light : dark,
        },
        ...commonShadow,
      };
    case 'containedInv':
      return {
        color: theme.palette.grey[300],
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        '&:hover': {
          color: main,
          backgroundColor: theme.palette.grey[200],
        },
        ...commonShadow,
      };
    case 'containedAlt':
      return {
        color: theme.palette.text.primary,
        borderColor: theme.palette.grey[300],
        backgroundColor: theme.palette.common.white,
        '&:hover': {
          backgroundColor: main,
          borderColor: main,
          color: contrastText,
        },
        ...commonShadow,
      };
    case 'shadow':
      return {
        color: contrastText,
        backgroundColor: main,
        boxShadow: shadows,
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: dark,
        },
        ...commonShadow,
      };
    case 'outlined':
      return {
        color: dark,
        borderColor: theme.palette.grey[300],
        backgroundColor: theme.palette.common.white,
        '&:hover': {
          backgroundColor: theme.palette.common.white,
          borderColor: theme.palette.primary.light,
          color: theme.palette.primary.light,
        },
        ...commonShadow,
      };
    case 'outlinedAlt':
      return {
        color: dark,
        borderColor: theme.palette.grey[300],
        backgroundColor: theme.palette.common.white,
        '&:hover': {
          backgroundColor: theme.palette.grey[200],
        },
        ...commonShadow,
      };
    case 'dashed':
      return {
        color: main,
        borderColor: main,
        backgroundColor: lighter,
        '&:hover': {
          color: dark,
          borderColor: dark,
        },
        ...commonShadow,
      };
    case 'textAlt':
      return {
        color: main,
        '&:hover': {
          backgroundColor: theme.palette.grey[200],
        },
      };

    case 'text':
    default:
      return {
        '&:hover': {
          color: dark,
          backgroundColor: lighter,
        },
        ...commonShadow,
      };
  }
}

// ==============================|| OVERRIDES - BUTTON ||============================== //

export default function Button(theme: Theme) {
  const primaryDashed = getColorStyle({ variant: 'dashed', color: 'primary', theme });
  const primaryShadow = getColorStyle({ variant: 'shadow', color: 'primary', theme });

  const disabledStyle = {
    '&.Mui-disabled': {
      backgroundColor: theme.palette.grey[200],
    },
  };
  const iconStyle = {
    '& svg': {
      fontSize: 'inherit',
    },
    '&.MuiButton-icon': {
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 0,
      marginRight: 0,
    },
  };

  return {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          fontWeight: 400,
          whiteSpace: 'nowrap',
          '&::after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            borderRadius: 4,
            opacity: 0,
            transition: 'all 0.5s',
          },

          '&:active::after': {
            position: 'absolute',
            borderRadius: 4,
            left: 0,
            top: 0,
            opacity: 1,
            transition: '0s',
          },
        },
        contained: {
          boxShadow: '0px 2px 0px 0px rgba(0, 0, 0, 0.04)',
          ...disabledStyle,
          '&.MuiButton-containedSwartz': {
            '&.Mui-disabled': {
              backgroundColor: 'black',
              color: theme.palette.swartz.lighter,
            },
          },
        },
        containedInv: {
          border: '1px solid',
          '&.Mui-disabled': {
            backgroundColor: theme.palette.grey[100],
          },
        },
        containedAlt: {
          border: '1px solid',
        },
        outlined: {
          boxShadow: '0px 2px 0px 0px rgba(0, 0, 0, 0.04)',
          ...disabledStyle,
        },
        outlinedAlt: {
          border: '1px solid',
          boxShadow: '0px 2px 0px 0px rgba(0, 0, 0, 0.04)',
          backgroundColor: theme.palette.grey[0],
          '&.Mui-disabled': {
            backgroundColor: theme.palette.grey[100],
          },
        },
        text: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        endIcon: {
          ...iconStyle,
        },
        startIcon: {
          ...iconStyle,
        },
        dashed: {
          border: '1px dashed',
          ...primaryDashed,
          '&.MuiButton-dashedPrimary': getColorStyle({ variant: 'dashed', color: 'primary', theme }),
          '&.MuiButton-dashedSecondary': getColorStyle({ variant: 'dashed', color: 'secondary', theme }),
          '&.MuiButton-dashedError': getColorStyle({ variant: 'dashed', color: 'error', theme }),
          '&.MuiButton-dashedSuccess': getColorStyle({ variant: 'dashed', color: 'success', theme }),
          '&.MuiButton-dashedInfo': getColorStyle({ variant: 'dashed', color: 'info', theme }),
          '&.MuiButton-dashedWarning': getColorStyle({ variant: 'dashed', color: 'warning', theme }),
          '&.MuiButton-dashedSwartz': getColorStyle({ variant: 'dashed', color: 'swartz', theme }),
          '&.Mui-disabled': {
            color: `${theme.palette.grey[300]} !important`,
            borderColor: `${theme.palette.grey[400]} !important`,
            backgroundColor: `${theme.palette.grey[200]} !important`,
          },
        },
        shadow: {
          ...primaryShadow,
          '&.MuiButton-shadowPrimary': getColorStyle({ variant: 'shadow', color: 'primary', theme }),
          '&.MuiButton-shadowSecondary': getColorStyle({ variant: 'shadow', color: 'secondary', theme }),
          '&.MuiButton-shadowError': getColorStyle({ variant: 'shadow', color: 'error', theme }),
          '&.MuiButton-shadowSuccess': getColorStyle({ variant: 'shadow', color: 'success', theme }),
          '&.MuiButton-shadowInfo': getColorStyle({ variant: 'shadow', color: 'info', theme }),
          '&.MuiButton-shadowWarning': getColorStyle({ variant: 'shadow', color: 'warning', theme }),
          '&.MuiButton-shadowSwartz': getColorStyle({ variant: 'shadow', color: 'swartz', theme }),
          '&.Mui-disabled': {
            color: `${theme.palette.grey[300]} !important`,
            borderColor: `${theme.palette.grey[400]} !important`,
            backgroundColor: `${theme.palette.grey[200]} !important`,
          },
        },
        containedPrimary: getColorStyle({ variant: 'contained', color: 'primary', theme }),
        containedSecondary: getColorStyle({ variant: 'contained', color: 'secondary', theme }),
        containedError: getColorStyle({ variant: 'contained', color: 'error', theme }),
        containedSuccess: getColorStyle({ variant: 'contained', color: 'success', theme }),
        containedInfo: getColorStyle({ variant: 'contained', color: 'info', theme }),
        containedWarning: getColorStyle({ variant: 'contained', color: 'warning', theme }),
        containedSwartz: getColorStyle({ variant: 'contained', color: 'swartz', theme }),
        containedInvPrimary: getColorStyle({ variant: 'containedInv', color: 'primary', theme }),
        containedInvSecondary: getColorStyle({ variant: 'containedInv', color: 'secondary', theme }),
        containedInvError: getColorStyle({ variant: 'containedInv', color: 'error', theme }),
        containedInvSuccess: getColorStyle({ variant: 'containedInv', color: 'success', theme }),
        containedInvInfo: getColorStyle({ variant: 'containedInv', color: 'info', theme }),
        containedInvWarning: getColorStyle({ variant: 'containedInv', color: 'warning', theme }),
        containedInvSwartz: getColorStyle({ variant: 'containedInv', color: 'swartz', theme }),
        containedAltPrimary: getColorStyle({ variant: 'containedAlt', color: 'primary', theme }),
        containedAltSecondary: getColorStyle({ variant: 'containedAlt', color: 'secondary', theme }),
        containedAltError: getColorStyle({ variant: 'containedAlt', color: 'error', theme }),
        containedAltSuccess: getColorStyle({ variant: 'containedAlt', color: 'success', theme }),
        containedAltInfo: getColorStyle({ variant: 'containedAlt', color: 'info', theme }),
        containedAltWarning: getColorStyle({ variant: 'containedAlt', color: 'warning', theme }),
        containedAltSwartz: getColorStyle({ variant: 'containedAlt', color: 'swartz', theme }),
        outlinedPrimary: getColorStyle({ variant: 'outlined', color: 'primary', theme }),
        outlinedSecondary: getColorStyle({ variant: 'outlined', color: 'secondary', theme }),
        outlinedError: getColorStyle({ variant: 'outlined', color: 'error', theme }),
        outlinedSuccess: getColorStyle({ variant: 'outlined', color: 'success', theme }),
        outlinedInfo: getColorStyle({ variant: 'outlined', color: 'info', theme }),
        outlinedWarning: getColorStyle({ variant: 'outlinedAlt', color: 'warning', theme }),
        outlinedSwartz: getColorStyle({ variant: 'outlinedAlt', color: 'swartz', theme }),
        outlinedAltPrimary: getColorStyle({ variant: 'outlinedAlt', color: 'primary', theme }),
        outlinedAltSecondary: getColorStyle({ variant: 'outlinedAlt', color: 'secondary', theme }),
        outlinedAltError: getColorStyle({ variant: 'outlinedAlt', color: 'error', theme }),
        outlinedAltSuccess: getColorStyle({ variant: 'outlinedAlt', color: 'success', theme }),
        outlinedAltInfo: getColorStyle({ variant: 'outlinedAlt', color: 'info', theme }),
        outlinedAltWarning: getColorStyle({ variant: 'outlinedAlt', color: 'warning', theme }),
        outlinedAltSwartz: getColorStyle({ variant: 'outlinedAlt', color: 'swartz', theme }),
        textPrimary: getColorStyle({ variant: 'text', color: 'primary', theme }),
        textSecondary: getColorStyle({ variant: 'text', color: 'secondary', theme }),
        textError: getColorStyle({ variant: 'text', color: 'error', theme }),
        textSuccess: getColorStyle({ variant: 'text', color: 'success', theme }),
        textInfo: getColorStyle({ variant: 'text', color: 'info', theme }),
        textWarning: getColorStyle({ variant: 'text', color: 'warning', theme }),
        textSwartz: getColorStyle({ variant: 'text', color: 'swartz', theme }),
        textAltPrimary: getColorStyle({ variant: 'textAlt', color: 'primary', theme }),
        textAltSecondary: getColorStyle({ variant: 'textAlt', color: 'secondary', theme }),
        textAltError: getColorStyle({ variant: 'textAlt', color: 'error', theme }),
        textAltSuccess: getColorStyle({ variant: 'textAlt', color: 'success', theme }),
        textAltInfo: getColorStyle({ variant: 'textAlt', color: 'info', theme }),
        textAltWarning: getColorStyle({ variant: 'textAlt', color: 'warning', theme }),
        textAltSwartz: getColorStyle({ variant: 'textAlt', color: 'swartz', theme }),
        sizeExtraSmall: {
          ...theme.typography.body3Regular,
          padding: '0.5px 8px',
          minWidth: 'unset',
          borderRadius: '4px',
          borderWidth: '0.5px',
          boxShadow: 'none',
          '&:hover': {
            borderWidth: '0.5px',
          },
        },
        sizeSmall: {
          padding: '3px 8px',
          borderRadius: '4px',
          minWidth: '30px',
          fontSize: '12px',
          fontWeight: 500,
          lineHeight: '20px',
          height: '26px',
          gap: '8px',
        },
        sizeMedium: {
          padding: '7px 16px',
          borderRadius: '6px',
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '22px',
          height: '36px',
          gap: '8px',
        },
        sizeLarge: {
          padding: '10px 16px',
          borderRadius: '6px',
          fontSize: '16px',
          fontWeight: 500,
          height: '44px',
          gap: '8px',
        },
        sizeSmallSquare: {
          padding: '2px 2px',
          borderRadius: '6px',
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '20px',
          height: '26px',
          minWidth: '26px',
          gap: '8px',
        },
        sizeMediumSquare: {
          padding: '4px 4px',
          borderRadius: '6px',
          fontSize: '20px',
          fontWeight: 500,
          lineHeight: '22px',
          height: '36px',
          minWidth: '36px',
          gap: '8px',
        },
        sizeLargeSquare: {
          padding: '8px 8px',
          borderRadius: '6px',
          fontSize: '24px',
          fontWeight: 500,
          lineHeight: '28px',
          height: '44px',
          minWidth: '44px',
          gap: '8px',
        },
        sizeTableHeaderSquare: {
          padding: '2px 2px',
          borderRadius: '4px',
          fontSize: '12px',
          fontWeight: 500,
          lineHeight: '14px',
          height: '22px',
          minWidth: '22px',
          gap: '8px',
        },
      },
    },
  };
}
