import { Theme } from '@mui/material/styles';

export default function ToggleButton(theme: Theme) {
  return {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          ...theme.typography.bodyRegular,
          color: theme.palette.text.primary,
          backgroundColor: 'transparent',
          padding: '6px 16px',
          borderRadius: 0,
          border: `1px solid ${theme.palette.secondary.light}`,
          '&.Mui-disabled': {
            border: 'unset',
            margin: 0,
          },
          '&.Mui-selected': {
            color: theme.palette.primary.main,
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: 'rgba(38, 38, 38, 0.04)',
            },
          },
          '&.Mui-selected[role="radio"]': {
            zIndex: 1,
            border: `1px solid ${theme.palette.primary.main}`,
          },
        },
      },
    },
  };
}
