import { Theme } from '@mui/material/styles';

export default function DataGrid(theme: Theme) {
  const { palette, shape } = theme;

  const headerColour = palette.grey[50];
  const outerBorderColour = palette.secondary.light;
  const innerBorderColour = palette.grey[200];
  const backgroundColour = palette.grey[0];
  const hoverColour = palette.grey[50];

  return {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          lineHeight: '22px',
          border: 0,

          '& .MuiDataGrid-columnHeaderTitleContainerContent': {
            width: '100%',
          },

          '& .MuiDataGrid-columnSeparator': {
            color: outerBorderColour,
            '&:hover': {
              color: palette.secondary[400],
            },
          },

          '& .MuiDataGrid-filler': {
            borderTop: 1,
            borderTopStyle: 'solid',
            borderTopColor: innerBorderColour,
          },

          '& .MuiDataGrid-scrollbarFiller': {
            backgroundColor: `${headerColour} !important`,
          },
        },
        main: {
          border: '1px solid',
          borderColor: `${outerBorderColour}`,
          borderBottomColor: `${innerBorderColour}`,
          borderTopLeftRadius: shape.borderRadius,
          borderTopRightRadius: shape.borderRadius,
          backgroundColor: backgroundColour,
        },
        footerContainer: {
          border: `1px solid`,
          borderTop: 0,
          borderColor: `${outerBorderColour} !important`,
          borderBottomRightRadius: shape.borderRadius,
          borderBottomLeftRadius: shape.borderRadius,
          backgroundColor: backgroundColour,
        },
        row: {
          borderTop: 0,
          '&:hover': {
            backgroundColor: `${hoverColour} !important`,
            '& .MuiDataGrid-cell--pinnedRight': {
              backgroundColor: `${hoverColour} !important`,
            },
          },
        },
        cell: {
          padding: '0px 12px',
          borderTop: '1px solid',
          borderTopColor: innerBorderColour,
          '&--pinnedRight': {
            backgroundColor: backgroundColour,
            paddingLeft: '8px !important',
            paddingRight: '10px !important',
          },
          '&:focus': {
            outline: 'none',
          },
          '&:focus-within': {
            outline: 'none',
          },
        },
        columnHeader: {
          padding: '0px 12px',
          fontWeight: 500,
          width: '100%',
          backgroundColor: headerColour,
          height: '50px !important',
          cursor: 'auto',
          '&--pinnedRight': {
            borderLeft: 0,
          },
          '&:focus': {
            outline: 'none',
          },
          '&:focus-within': {
            outline: 'none',
          },
        },
        iconButtonContainer: {
          display: 'none',
        },
        detailPanel: {
          backgroundColor: backgroundColour,
        },
        detailPanelToggleCell: {
          height: '20px',
          width: '20px',
        },
      },
    },
  };
}
