import { Theme } from '@mui/material';

export default function ImageListItemBar(theme: Theme) {
  return {
    MuiImageListItemBar: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.80)',
        },
        titleWrap: {
          padding: '24px',
          maxHeight: '100%',
          overflow: 'hidden',
        },
        title: {
          ...theme.typography.headingBold,
          color: theme.palette.secondary.contrastText,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          marginBottom: '6px',
        },
        subtitle: {
          ...theme.typography.bodyRegular,
          color: theme.palette.secondary.contrastText,
          whiteSpace: 'normal',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: 'block',
          lineHeight: '22px',
          maxHeight: '44px',
        },
      },
    },
  };
}
