import { Box, Button, Grid2 as Grid, Stack } from '@mui/material';
import { useNavigate } from '@router';
import { useTranslation } from 'react-i18next';
import { StateBox } from '@components/ng/statebox';

export default function NotFoundPage() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleClickBack = () => {
    navigate(-1);
  };

  const handleClickHome = () => {
    navigate('/');
  };

  return (
    <Grid
      container
      direction="column"
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: '508px',
        }}
      >
        <StateBox
          title={t('error/404/primary')}
          subtitle={t('error/404/secondary')}
        >
          <Stack
            direction="row"
            spacing={2}
          >
            <Button
              variant="contained"
              onClick={handleClickBack}
            >
              {t('action/backtoprevious')}
            </Button>
            <Button
              color="secondary"
              variant="outlined"
              onClick={handleClickHome}
            >
              {t('action/returnhome')}
            </Button>
          </Stack>
        </StateBox>
      </Box>
    </Grid>
  );
}
