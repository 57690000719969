import { flatten } from 'flat';
import { useMemo } from 'react';
import { FlatSubstitutions, Substitutions } from './types';

export function makeSubstitutions(substitutions?: Substitutions): FlatSubstitutions {
  return flatten<Substitutions, FlatSubstitutions>(substitutions ?? {});
}

export function useFlatSubstitutions(substitutions?: Substitutions): FlatSubstitutions {
  return useMemo(() => makeSubstitutions(substitutions), [substitutions]);
}
