import { Theme } from '@mui/material/styles';

export default function PickersCallendarHeader(theme: Theme) {
  return {
    MuiPickersCalendarHeader: {
      styleOverrides: {
        root: {
          padding: 0,
          paddingLeft: 16,
          paddingRight: 16,

          margin: 0,
        },
        label: {
          ...theme.typography.body2Medium,
        },
        switchViewButton: {
          color: theme.palette.action.disabled,
          padding: 0,
          width: 12,
          height: 12,
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
  };
}
