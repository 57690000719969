import { ExtendedStyleProps } from '@mantis/types/extended';
import getColors from '@mantis/utils/getColors';
import { Theme } from '@mui/material/styles';
import { CloseFillIcon } from '@components/actionicons';

function getColor({ color, theme }: ExtendedStyleProps) {
  const colors = getColors(theme, color);
  const { dark } = colors;

  return {
    '&.Mui-focusVisible': {
      outline: `2px solid ${dark}`,
      outlineOffset: 2,
    },
  };
}

function getColorStyle({ color, theme }: ExtendedStyleProps) {
  const colors = getColors(theme, color);
  const { light, lighter, main } = colors;

  return {
    color: main,
    backgroundColor: lighter,
    borderColor: light,
    '& .MuiChip-deleteIcon': {
      color: main,
      '&:hover': {
        color: light,
      },
    },
  };
}

export default function Chip(theme: Theme) {
  const defaultLightChip = getColorStyle({ color: 'secondary', theme });
  return {
    MuiChip: {
      defaultProps: {
        deleteIcon: (
          <CloseFillIcon
            height={12}
            width={12}
          />
        ),
      },
      styleOverrides: {
        root: {
          borderRadius: 4,
          '&:active': {
            boxShadow: 'none',
          },
          '&.MuiChip-colorPrimary': getColor({ color: 'primary', theme }),
          '&.MuiChip-colorSecondary': getColor({ color: 'secondary', theme }),
          '&.MuiChip-colorError': getColor({ color: 'error', theme }),
          '&.MuiChip-colorInfo': getColor({ color: 'info', theme }),
          '&.MuiChip-colorSuccess': getColor({ color: 'success', theme }),
          '&.MuiChip-colorWarning': getColor({ color: 'warning', theme }),
          '&.MuiChip-colorSwartz': getColor({ color: 'swartz', theme }),
        },
        sizeSmall: {
          ...theme.typography.body2Description,
          height: 20,
        },
        sizeMedium: {
          ...theme.typography.body2Description,
          height: 24,
        },
        sizeLarge: {
          fontSize: '1rem',
          height: 40,
        },
        light: {
          ...defaultLightChip,
          '&.MuiChip-lightPrimary': getColorStyle({ color: 'primary', theme }),
          '&.MuiChip-lightSecondary': getColorStyle({ color: 'secondary', theme }),
          '&.MuiChip-lightError': getColorStyle({ color: 'error', theme }),
          '&.MuiChip-lightInfo': getColorStyle({ color: 'info', theme }),
          '&.MuiChip-lightSuccess': getColorStyle({ color: 'success', theme }),
          '&.MuiChip-lightWarning': getColorStyle({ color: 'warning', theme }),
          '&.MuiChip-lightSwartz': {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary[100],
            '& .MuiChip-deleteIcon': {
              color: theme.palette.text.disabled,
              '&:hover': {
                color: theme.palette.secondary.main,
              },
            },
          },
        },
        outlined: {
          backgroundColor: theme.palette.common.white,
          '&.MuiChip-colorSecondary': {
            ...theme.typography.tagLabelBold,
            borderColor: theme.palette.secondary[200],
          },
          '&.MuiChip-colorSwartz': {
            borderColor: theme.palette.secondary.light,
            '& .MuiChip-deleteIcon': {
              color: theme.palette.text.primary,
              '&:hover': {
                color: theme.palette.secondary.main,
              },
            },
          },
        },
        combined: {
          border: '1px solid',
          ...defaultLightChip,
          '&.MuiChip-combinedPrimary': {
            color: theme.palette.primary[700],
            backgroundColor: theme.palette.primary[100],
            borderColor: theme.palette.primary[200],
            '& .MuiChip-deleteIcon': {
              color: theme.palette.primary[700],
              '&:hover': {
                color: theme.palette.primary[200],
              },
            },
          },
          '&.MuiChip-combinedSecondary': {
            color: theme.palette.secondary.main,
            backgroundColor: theme.palette.grey[50],
            borderColor: theme.palette.secondary[200],
            ...theme.typography.tagLabelBold,
            '& .MuiChip-deleteIcon': {
              color: theme.palette.secondary.main,
              '&:hover': {
                color: theme.palette.secondary[200],
              },
            },
          },
          '&.MuiChip-combinedError': getColorStyle({ color: 'error', theme }),
          '&.MuiChip-combinedInfo': getColorStyle({ color: 'info', theme }),
          '&.MuiChip-combinedSuccess': getColorStyle({ color: 'success', theme }),
          '&.MuiChip-combinedWarning': getColorStyle({ color: 'warning', theme }),
          '&.MuiChip-combinedSwartz': {
            color: theme.palette.text.primary,
            borderColor: theme.palette.secondary.light,
            backgroundColor: theme.palette.secondary[100],
            '& .MuiChip-deleteIcon': {
              color: theme.palette.text.disabled,
              '&:hover': {
                color: theme.palette.secondary.main,
              },
            },
          },
        },
        filled: {
          '&.MuiChip-filledSwartz': {
            backgroundColor: theme.palette.text.primary,
            color: theme.palette.common.white,
            '& .MuiChip-deleteIcon': {
              color: theme.palette.text.disabled,
              '&:hover': {
                color: theme.palette.secondary.main,
              },
            },
          },
        },
        deleteIcon: {
          marginRight: '4px',
          marginLeft: '-5px',
        },
        label: {
          paddingLeft: '8px',
          paddingRight: '8px',
        },
      },
    },
  };
}
