import { ApolloClient, InMemoryCache } from '@apollo/client';
import { scalarTypePolicies } from '@api/deianira/generated/scalar-type-policies';

export function makeClient() {
  return new ApolloClient({
    uri: '/graphql',
    queryDeduplication: false,
    assumeImmutableResults: true,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only',
      },
      query: {
        fetchPolicy: 'network-only',
      },
    },
    cache: new InMemoryCache({
      typePolicies: scalarTypePolicies,
    }),
  });
}

export const client = makeClient();
