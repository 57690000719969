import { Box, Skeleton, Stack, useMediaQuery, useTheme } from '@mui/material';
import { SubpageContent, SubpageLayoutContent } from '@features/ng/layout';

interface PageContentSkeletonProps {
  size: 'standard' | 'small';
  enabled?: boolean;
}

export function PageContentHeaderSkeleton({ enabled = true }: Pick<PageContentSkeletonProps, 'enabled'>) {
  return (
    <Skeleton
      animation={enabled ? 'wave' : false}
      variant="rectangular"
      width="100%"
      height={68}
    />
  );
}

function PageContentHorizontalBlockSkeleton({ size, enabled = true }: PageContentSkeletonProps) {
  return (
    <Stack
      direction="row"
      sx={{
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <Stack
        direction="row"
        sx={{
          gap: {
            sm: 3,
            lg: 10,
          },
          width: '100%',
          maxWidth: 1150,
        }}
      >
        <Box
          sx={{
            flexBasis: 200,
            flexShrink: 0,
            flexGrow: 1,
          }}
        >
          <Skeleton
            variant="rounded"
            component="div"
            animation={enabled ? 'wave' : false}
            width="100%"
            height={24}
          />
        </Box>
        <Box
          sx={{
            flexBasis: 400,
            flexShrink: 0,
            flexGrow: 2,
          }}
        >
          <Skeleton
            variant="rounded"
            component="div"
            animation={enabled ? 'wave' : false}
            width="100%"
            height={size === 'standard' ? 304 : 200}
          />
        </Box>
      </Stack>
    </Stack>
  );
}

function PageContentVerticalBlockSkeleton({ size, enabled = true }: PageContentSkeletonProps) {
  const theme = useTheme();
  const mediumOrSmaller = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Stack
      direction="row"
      sx={{
        width: '100%',
        justifyContent: 'center',
      }}
    >
      <Stack
        direction="column"
        sx={{
          gap: 2,
          width: '100%',
          minWidth: 0,
          maxWidth: mediumOrSmaller ? 680 : 1150,
        }}
      >
        <Box>
          <Skeleton
            variant="rounded"
            component="div"
            animation={enabled ? 'wave' : false}
            width="200px"
            height={48}
          />
        </Box>
        <Box>
          <Skeleton
            variant="rounded"
            component="div"
            animation={enabled ? 'wave' : false}
            width="100%"
            height={size === 'standard' ? 304 : 200}
          />
        </Box>
      </Stack>
    </Stack>
  );
}

function PageContentBlockSkeleton({ size, enabled = true }: PageContentSkeletonProps) {
  const theme = useTheme();
  const mediumOrSmaller = useMediaQuery(theme.breakpoints.down('md'));

  if (mediumOrSmaller) {
    return (
      <PageContentVerticalBlockSkeleton
        size={size}
        enabled={enabled}
      />
    );
  }

  return (
    <PageContentHorizontalBlockSkeleton
      size={size}
      enabled={enabled}
    />
  );
}

export function PageContentSkeleton({ enabled }: Pick<PageContentSkeletonProps, 'enabled'>) {
  return (
    <SubpageContent>
      <PageContentBlockSkeleton
        size="standard"
        enabled={enabled}
      />
      <PageContentBlockSkeleton
        size="small"
        enabled={enabled}
      />
    </SubpageContent>
  );
}

export function ReportSkeleton({ enabled }: Pick<PageContentSkeletonProps, 'enabled'>) {
  return (
    <SubpageContent>
      <Stack
        direction="row"
        sx={{
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: 1440,
        }}
      >
        <Skeleton
          variant="rounded"
          component="div"
          animation={enabled ? 'wave' : false}
          width="100%"
          height="calc(100vh - 96px)"
        />
      </Stack>
    </SubpageContent>
  );
}

export function FullPageContentSkeleton({ enabled }: Pick<PageContentSkeletonProps, 'enabled'>) {
  return (
    <SubpageLayoutContent header={<PageContentHeaderSkeleton enabled={enabled} />}>
      <PageContentSkeleton enabled={enabled} />
    </SubpageLayoutContent>
  );
}
