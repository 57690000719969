import { Theme } from '@mui/material/styles';

export default function Popover(theme: Theme) {
  return {
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: theme.customShadows.popover,
          marginTop: '4px',
          padding: '12px',
          border: '1px solid',
          borderColor: theme.palette.secondary.light,
          borderRadius: theme.shape.borderRadius,
        },
      },
    },
  };
}
