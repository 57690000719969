import { Theme } from '@mui/material/styles';

export default function FormHelperText(theme: Theme) {
  return {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: 0,
          marginBottom: '2px',
          ...theme.typography.body2Description,
        },
      },
    },
  };
}
