import { gql } from '@api/deianira/generated/gql';

export const GET_USER_PROFILE = gql(/* GraphQL */ `
  query GetUserProfile {
    userProfile {
      id
      displayName
      userName
      name {
        givenName
        familyName
      }
      emails {
        value
        type
      }
      groups {
        id
        displayName
      }
    }
  }
`);

export const SWITCH_USER = gql(/* GraphQL */ `
  mutation SwitchUser($user: SwitchUserInput!) {
    switchUser(user: $user) {
      id
      displayName
      userName
      name {
        givenName
        familyName
      }
      emails {
        value
        type
      }
      groups {
        id
        displayName
      }
    }
  }
`);
