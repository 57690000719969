export default function TouchRipple() {
  return {
    MuiTouchRipple: {
      styleOverrides: {
        root: {
          display: 'none',
        },
      },
    },
  };
}
