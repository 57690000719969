import { Theme } from '@mui/material/styles';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function Avatar(theme: Theme) {
  return {
    MuiAvatar: {
      styleOverrides: {
        root: {
          borderRadius: '6px',
          bgcolor: theme.palette.secondary.lighter,
          width: '36px',
          height: '36px',
        },
      },
    },
  };
}
