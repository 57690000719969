// material-ui
import { Theme } from '@mui/material/styles';

// ==============================|| OVERRIDES - ALERT TITLE ||============================== //

export default function DropzoneBase(theme: Theme) {
  return {
    MuiDropzoneArea: {
      styleOverrides: {
        root: {
          minHeight: '150px',
        },
        text: {
          '&.Mui-disabled': {
            color: theme.palette.secondary.light,
          },
        },
      },
    },
  };
}
