import { Box, BoxProps, Button, CircularProgress, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { LoadingStateVariant } from './types';

function Spinner({ text }: { text?: string }) {
  return (
    <Stack
      spacing={1.5}
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        width: 200,
        textAlign: 'center',
      }}
    >
      <CircularProgress />
      {text && (
        <Typography
          variant="bodyRegular"
          sx={{
            color: 'text.primary',
          }}
        >
          {text}
        </Typography>
      )}
    </Stack>
  );
}

function TextWithDot({
  text,
  children,
  colour = 'error.main',
}: {
  text: string;
  children?: React.ReactNode;
  colour?: BoxProps['bgcolor'];
}) {
  return (
    <Stack
      direction="row"
      spacing={1.5}
      sx={{
        alignItems: 'center',
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        sx={{
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: 6,
            height: 6,
            bgcolor: colour,
            borderRadius: '50%',
          }}
        />
        <Typography
          variant="bodyRegular"
          sx={{
            color: 'text.secondary',
          }}
        >
          {text}
        </Typography>
      </Stack>
      {children}
    </Stack>
  );
}

export interface UseLoadingStateContentOptions {
  variant: LoadingStateVariant;
  displayText?: string;
  retryText: string;
  onRetry?: () => void;
  emptyColour?: BoxProps['bgcolor'];
}

export function useLoadingStateContent({
  variant,
  displayText,
  retryText,
  emptyColour = 'warning.main',
  onRetry,
}: UseLoadingStateContentOptions) {
  return useMemo(() => {
    switch (variant) {
      case 'loading':
        return <Spinner text={displayText} />;

      case 'permanentFailure':
        return <TextWithDot text={displayText ?? ''} />;

      case 'temporaryFailure':
        return (
          <TextWithDot text={displayText ?? ''}>
            {onRetry && (
              <Button
                size="small"
                variant="outlined"
                color="secondary"
                onClick={onRetry}
              >
                {retryText}
              </Button>
            )}
          </TextWithDot>
        );

      case 'empty':
        return (
          <TextWithDot
            colour={emptyColour}
            text={displayText ?? ''}
          />
        );

      case 'disabled':
        return displayText;

      case 'initial':
        if (displayText?.length === 0) {
          return null;
        }

        return (
          <TextWithDot
            colour="primary.main"
            text={displayText ?? ''}
          />
        );

      default:
        return null;
    }
  }, [variant, displayText, retryText, emptyColour, onRetry]);
}
