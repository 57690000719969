export default function DialogTitle() {
  return {
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: 0,
          marginBottom: 8,
        },
      },
    },
  };
}
