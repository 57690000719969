export default function YearCalendar() {
  return {
    MuiYearCalendar: {
      styleOverrides: {
        root: {
          width: '280px',
          maxHeight: '223px',
        },
      },
    },
  };
}
