import { Button } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNotification } from '@components/mui/notifications';

type ReloadPromptProps = {
  needRefresh: boolean;
  setNeedRefresh: (value: boolean) => void;
  updateServiceWorker: (force: boolean) => void;
};

export function ReloadPrompt({ needRefresh, setNeedRefresh, updateServiceWorker }: ReloadPromptProps) {
  const { t } = useTranslation();

  const { info } = useNotification(null);

  useEffect(() => {
    if (needRefresh) {
      info(
        t('toast/info/update'),
        <Button
          variant="contained"
          color="swartz"
          onClick={() => {
            setNeedRefresh(false);
            updateServiceWorker(true);
          }}
          sx={{
            color: 'primary.main',
          }}
        >
          {t('action/reload')}
        </Button>,
      );
    }
  }, [t, info, needRefresh, setNeedRefresh, updateServiceWorker]);

  return null;
}
