import { styled } from '@mui/material';
import { SnackbarProvider as MuiSnackbarProvider } from 'notistack';
import { PropsWithChildren } from 'react';
import { ErrorIcon, InfoIcon, SuccessIcon, WarningIcon } from '@components/actionicons';

const StyledSnackbarProvider = styled(MuiSnackbarProvider)(({ theme }) => ({
  '&.notistack-MuiContent-default': {
    backgroundColor: theme.palette.primary.main,
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: theme.palette.error.main,
  },
  '&.notistack-MuiContent-success': {
    backgroundColor: theme.palette.success.main,
  },
  '&.notistack-MuiContent-info': {
    backgroundColor: theme.palette.info.main,
  },
  '&.notistack-MuiContent-warning': {
    backgroundColor: theme.palette.warning.main,
  },
}));

export function SnackbarProvider({ children }: PropsWithChildren) {
  return (
    <StyledSnackbarProvider
      iconVariant={{
        success: <SuccessIcon />,
        error: <ErrorIcon />,
        warning: <WarningIcon />,
        info: <InfoIcon />,
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      {children}
    </StyledSnackbarProvider>
  );
}
