import { checkboxClasses, formControlLabelClasses } from '@mui/material';
import { Theme } from '@mui/material/styles';

export default function FormControlLabel(theme: Theme) {
  return {
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          margin: 0,
          alignItems: 'flex-start',
          [`& .${checkboxClasses.root}`]: {
            marginTop: 3,
          },
        },
        label: {
          marginLeft: 8,
          ...theme.typography.bodyRegular,

          '&.Mui-disabled': {
            color: 'action.disabled',
          },
        },
        labelPlacementStart: {
          [`& .${formControlLabelClasses.label}`]: {
            marginLeft: 0,
            marginRight: 8,
          },
        },
      },
    },
  };
}
