export default function PickersLayout() {
  return {
    MuiPickersLayout: {
      styleOverrides: {
        root: {
          '& .MuiPickersLayout-actionBar': {
            height: '44px',
          },
        },
        contentWrapper: {
          maxHeight: '267px',
        },
      },
    },
  };
}
