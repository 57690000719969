/* eslint-disable no-console */
export function write(msg: string, location: string, ctx: object) {
  console.groupCollapsed(msg);
  console.log(location);

  if (Object.keys(ctx).length > 0) {
    console.log(ctx);
  }

  console.groupEnd();
}
