export default function PickersArrowSwitcher() {
  return {
    MuiPickersArrowSwitcher: {
      styleOverrides: {
        root: {},
        button: {
          padding: 0,
          width: 16,
          height: 16,
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '> svg': {
            width: 16,
            height: 16,
          },
        },
        spacer: {
          width: 48,
        },
      },
    },
  };
}
