import type { Theme } from '@mui/material/styles';

export default function MenuItem(theme: Theme) {
  return {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          minHeight: 0,
          padding: '7px 12px',
          borderRadius: '6px !important',
          '&:hover': {
            backgroundColor: `${theme.palette.secondary[100]} !important`,
          },
          '&.Mui-focused': {
            backgroundColor: `${theme.palette.secondary[100]} !important`,
          },
          '&.Mui-selected': {
            backgroundColor: `${theme.palette.grey.A800}`,
            '&:hover': {
              backgroundColor: `${theme.palette.grey.A800} !important`,
            },

            '& svg': {
              color: theme.palette.primary.main,
            },
          },
        },
      },
    },
  };
}
