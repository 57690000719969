import { Theme, tooltipClasses } from '@mui/material';

export default function Tooltip(theme: Theme) {
  return {
    MuiTooltip: {
      defaultProps: {
        enterDelay: 500,
        slotProps: {
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -6],
                },
              },
            ],
          },
        },
      },
      styleOverrides: {
        tooltip: {
          color: theme.palette.common.white,
          backgroundColor: theme.palette.secondary.dark,
          padding: '6px 8px 6px 8px',
          ...theme.typography.body2Description,
          borderRadius: '6px',
          maxWidth: '200px',
          textAlign: 'center',
        },
        arrow: {
          color: theme.palette.secondary.dark,
        },
        popper: {
          [`&[data-popper-placement*="bottom"] .${tooltipClasses.arrow}`]: {
            top: '2px !important',
          },
          [`&[data-popper-placement*="top"] .${tooltipClasses.arrow}`]: {
            bottom: '2px !important',
          },
          [`&[data-popper-placement*="right"] .${tooltipClasses.arrow}`]: {
            left: '2px !important',
          },
          [`&[data-popper-placement*="left"] .${tooltipClasses.arrow}`]: {
            right: '2px !important',
          },
        },
      },
    },
  };
}
