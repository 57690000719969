export default function DialogActions() {
  return {
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
  };
}
