import { FieldPolicy } from '@apollo/client';
import { JSONContent } from '@tiptap/react';
import isNil from 'lodash/isNil';

export const jsonObjectTypePolicy: FieldPolicy<JSONContent | null, string> = {
  merge: (_, incoming) => {
    if (isNil(incoming)) {
      return incoming;
    }
    if (typeof incoming === 'object') {
      return incoming;
    }
    try {
      return JSON.parse(incoming) as JSONContent;
    } catch {
      return null;
    }
  },
};
