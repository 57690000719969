export default function Skeleton() {
  return {
    MuiSkeleton: {
      styleOverrides: {
        root: {
          borderRadius: '6px',
          '&::after': {
            animationDelay: '0.05s',
            animationDuration: '0.8s',
          },
        },
      },
    },
  };
}
