import { ColorProps } from '@mantis/types/extended';
import getColors from '@mantis/utils/getColors';
import getShadow from '@mantis/utils/getShadow';
import { Theme } from '@mui/material/styles';

interface Props {
  variant: ColorProps;
  theme: Theme;
}

// Border color and shadow
function getColor({ variant, theme }: Props) {
  const colors = getColors(theme, variant);
  const { light } = colors;

  const shadows = getShadow(theme, `${variant}`);

  return {
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: light,
    },
    '&.Mui-focused': {
      boxShadow: shadows,
      '& .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${light}`,
      },
    },
  };
}

// Outlined Input
export default function OutlinedInput(theme: Theme) {
  return {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: '6px 12px 6px 12px !important',

          '&.MuiInputBase-inputAdornedStart': {
            paddingLeft: '8px !important',
          },
          '&.MuiInputBase-inputAdornedEnd': {
            paddingRight: '8px !important',
          },

          boxSizing: 'border-box',
          height: '36px',
          borderRadius: '6px',

          '&::placeholder': {
            color: theme.palette.grey[300],
            opacity: 1,
          },
        },
        notchedOutline: {
          borderColor: theme.palette.grey[300],
        },
        root: {
          '& svg': {
            color: theme.palette.text.secondary,
          },

          ...getColor({ variant: 'primary', theme }),
          '&.Mui-error': {
            ...getColor({ variant: 'error', theme }),
          },
          '&.Mui-disabled': {
            borderColor: theme.palette.grey[100],
            backgroundColor: theme.palette.grey[100],
            color: theme.palette.grey[300],

            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.grey[300],
            },
          },

          boxSizing: 'border-box',
          borderRadius: '6px',

          // default
          padding: '0px 0px 0px 0px',
          '&.MuiInputBase-adornedStart': {
            paddingLeft: '12px',
          },

          '&.MuiInputBase-adornedEnd': {
            paddingRight: '14px',
          },

          // sizes
          '&.MuiInputBase-sizeSmall': {
            height: '26px',
            lineHeight: '20px',
            fontSize: '12px',
            borderRadius: '4px',
          },
          '&.MuiInputBase-sizeMedium': {
            height: '36px',
            lineHeight: '22px',
            fontSize: '14px',
            borderRadius: '6px',

            '&.MuiInputBase-adornedStart': {
              padding: '0px 24px 0px 12px',
            },
          },
          '&.MuiInputBase-sizeLarge': {
            height: '44px',
            lineHeight: '24px',
            fontSize: '16px',
            borderRadius: '6px',

            '&.MuiInputBase-adornedStart': {
              padding: '0px 24px 0px 12px',
            },
          },
        },
        inputSizeSmall: {
          padding: '4px 12px',
        },
        inputSizeMedium: {
          padding: '7px 12px',
        },
        inputSizeLarge: {
          padding: '7px 12px',
        },
        colorSecondary: getColor({ variant: 'secondary', theme }),
        colorError: getColor({ variant: 'error', theme }),
        colorWarning: getColor({ variant: 'warning', theme }),
        colorInfo: getColor({ variant: 'info', theme }),
        colorSuccess: getColor({ variant: 'success', theme }),
      },
    },
  };
}
