export default function DatePickerToolbar() {
  return {
    MuiDatePickerToolbar: {
      styleOverrides: {
        root: {
          display: 'none',
        },
      },
    },
  };
}
