import { Backdrop as BaseBackdrop, Box } from '@mui/material';
import { useMeasure } from 'react-use';

export interface BackdropProps {
  children: React.ReactNode;
  opacity: number;
  enabled?: boolean;
  content?: React.ReactNode;
}

export function Backdrop({ children, opacity, enabled, content }: BackdropProps) {
  const [ref, { width, height, top, left }] = useMeasure();

  return (
    <Box
      ref={ref}
      sx={{
        position: 'relative',
      }}
    >
      {children}
      <BaseBackdrop
        open={!!enabled}
        sx={theme => ({
          zIndex: theme.zIndex.drawer + 1,
          backgroundColor: `rgba(255, 255, 255, ${opacity})`,
          position: 'absolute',
          top,
          left,
          width,
          height,
        })}
      >
        {content}
      </BaseBackdrop>
    </Box>
  );
}
