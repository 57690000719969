export default function DialogContent() {
  return {
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '24px',
        },
      },
    },
  };
}
