import { Theme } from '@mui/material/styles';

export default function CardHeader(theme: Theme) {
  return {
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: 24,
          paddingBottom: 16,
          borderBottom: theme.borders.default,
          backgroundColor: theme.palette.secondary.lighter,
        },
        action: {
          marginRight: '-4px',
        },
      },
    },
  };
}
