import { Palette, createTheme } from '@mui/material/styles';

const black = '#000000';
const white = '#FFFFFF';

const makePalette = (): Palette => {
  const paletteColor = {
    primary: {
      lighter: '#E6F4FF',
      light: '#69B1FF',
      main: '#1890FF',
      dark: '#0958D9',
      darker: '#002C8C',
      contrastText: white,
      // other shades
      50: '#F0F2F5',
      100: '#E6F7FF',
      200: '#BAE7FF',
      300: '#40A9FF',
      400: '#4096FF',
      700: '#096DD9',
      900: '#001D66',
    },
    secondary: {
      lighter: '#F5F5F5',
      light: '#D9D9D9',
      main: '#8C8C8C',
      dark: '#262626',
      darker: '#000000',
      contrastText: white,
      // other shaedes
      100: '#F5F5F5',
      200: '#F0F0F0',
      400: '#BFBFBF',
      600: '#595959',
      800: '#141414',
      A100: '#FFFFFF',
      A200: '#434343',
      A300: '#1F1F1F',
    },
    error: {
      lighter: '#FFF1F0',
      light: '#FFA39E',
      main: '#FF4D4F',
      dark: '#A8071A',
      darker: '#5C0011',
      contrastText: white,
    },
    warning: {
      lighter: '#FFFBE6',
      light: '#FFD666',
      main: '#FAAD14',
      dark: '#AD6800',
      darker: '#613400',
      contrastText: '#F5F5F5',
    },
    info: {
      lighter: '#E6FFFB',
      light: '#5CDBD3',
      main: '#13C2C2',
      dark: '#006D75',
      darker: '#002329',
      contrastText: white,
    },
    success: {
      lighter: '#F6FFED',
      light: '#95DE64',
      main: '#52C41A',
      dark: '#237804',
      darker: '#092B00',
      contrastText: white,
      // other shades
      300: '#B7EB8F',
    },
    grey: {
      0: '#FFFFFF',
      50: '#FAFAFA',
      100: '#F5F5F5',
      200: '#F0F0F0',
      300: '#D9D9D9',
      400: '#BFBFBF',
      500: '#8C8C8C',
      600: '#595959',
      700: '#262626',
      800: '#141414',
      900: '#000000',
      A50: '#FAFAFB',
      A100: '#FAFAFA',
      A200: '#BFBFBF',
      A400: '#434343',
      A700: '#1F1F1F',
      A800: '#E6EBF1',
    },
    swartz: {
      lighter: '#262626',
      light: '#262626',
      main: '#000000',
      dark: '#000000',
      darker: '#000000',
      contrastText: white,
    },
  };

  return createTheme({
    palette: {
      mode: 'light',
      common: {
        black,
        white,
      },
      ...paletteColor,
      text: {
        primary: paletteColor.grey[700],
        secondary: paletteColor.grey[500],
        disabled: paletteColor.grey[400],
      },
      action: {
        disabled: paletteColor.grey[300],
      },
      divider: paletteColor.secondary.light,
      lightDivider: paletteColor.secondary[200],
      background: {
        paper: paletteColor.grey[0],
        default: paletteColor.grey.A50,
      },
    },
  }).palette;
};

export default makePalette;
