export default function InputBase() {
  return {
    MuiInputBase: {
      styleOverrides: {
        sizeSmall: {
          fontSize: '12px',
        },
        sizeMedium: {
          fontSize: '14px',
        },
        sizeLarge: {
          fontSize: '16px',
        },
      },
    },
  };
}
