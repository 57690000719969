import { BoxProps } from '@mui/material';
import { Backdrop } from '@components/ng/backdrop';
import { useLoadingStateContent } from './content';
import { LoadingStateVariant } from './types';

export type LoadingStateProps = {
  variant: LoadingStateVariant;
  opacity?: number;
  children?: React.ReactNode;
  displayText?: string;
  retryText: string;
  emptyColour?: BoxProps['bgcolor'];
  onRetry?: () => void;
};

export function LoadingBackdrop({
  opacity = 0.8,
  variant,
  displayText,
  retryText,
  emptyColour,
  onRetry,
  children,
}: LoadingStateProps) {
  const content = useLoadingStateContent({ variant, displayText, retryText, emptyColour, onRetry });

  return (
    <Backdrop
      enabled={!!content}
      opacity={opacity}
      content={content}
    >
      {children}
    </Backdrop>
  );
}
