import { Box, Skeleton } from '@mui/material';

export interface ReadOnlyBarSkeletonProps {
  enabled?: boolean;
}

export function ReadOnlyBarSkeleton({ enabled = true }: ReadOnlyBarSkeletonProps) {
  return (
    <Box
      sx={{
        padding: '4px',
        width: '100%',
        height: '100%',
      }}
    >
      <Skeleton
        component="div"
        animation={enabled ? 'wave' : false}
        variant="rectangular"
        width="100%"
        height={36}
      />
    </Box>
  );
}
