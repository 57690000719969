import { Theme } from '@mui/material/styles';
import _merge from 'lodash/merge';
import Accordion from './Accordion';
import AccordionDetails from './AccordionDetails';
import AccordionSummary from './AccordionSummary';
import Alert from './Alert';
import AlertTitle from './AlertTitle';
import Autocomplete from './Autocomplete';
import Avatar from './Avatar';
import Badge from './Badge';
import Button from './Button';
import ButtonBase from './ButtonBase';
import ButtonGroup from './ButtonGroup';
import Card from './Card';
import CardActions from './CardActions';
import CardContent from './CardContent';
import CardHeader from './CardHeader';
import Checkbox from './Checkbox';
import Chip from './Chip';
import DataGrid from './DataGrid';
import DateCalendar from './DateCalendar';
import DatePicker from './DatePicker';
import DatePickerToolbar from './DatePickerToolbar';
import DateTimePicker from './DateTimePicker';
import DateTimePickerToolbar from './DateTimePickerToolbar';
import DayCalendar from './DayCalendar';
import Dialog from './Dialog';
import DialogActions from './DialogActions';
import DialogContent from './DialogContent';
import DialogContentText from './DialogContentText';
import DialogTitle from './DialogTitle';
import Divider from './Divider';
import DropzoneBase from './DropzoneBase';
import Fab from './Fab';
import FormControlLabel from './FormControlLabel';
import FormHelperText from './FormHelperText';
import FormLabel from './FormLabel';
import Grid from './Grid';
import IconButton from './IconButton';
import ImageListItem from './ImageListItem';
import ImageListItemBar from './ImageListItemBar';
import InputBase from './InputBase';
import InputLabel from './InputLabel';
import LinearProgress from './LinearProgress';
import Link from './Link';
import List from './List';
import ListItemButton from './ListItemButton';
import ListItemIcon from './ListItemIcon';
import LoadingButton from './LoadingButton';
import MenuItem from './MenuItem';
import MultiSectionDigitalClockSection from './MultiSectionDigitalClockSection';
import OutlinedInput from './OutlinedInput';
import Pagination from './Pagination';
import PaginationItem from './PaginationItem';
import PickersArrowSwitcher from './PickersArrowSwitcher';
import PickersCalendarHeader from './PickersCalendarHeader';
import PickersDay from './PickersDay';
import PickersLayout from './PickersLayout';
import PickersToolbarText from './PickersToolbarText';
import PickersYear from './PickersYear';
import Popover from './Popover';
import Radio from './Radio';
import RichTreeView from './RichTreeView';
import Skeleton from './Skeleton';
import Slider from './Slider';
import SvgIcon from './SvgIcon';
import Switch from './Switch';
import Tab from './Tab';
import TableBody from './TableBody';
import TableCell from './TableCell';
import TableFooter from './TableFooter';
import TableHead from './TableHead';
import TablePagination from './TablePagination';
import TableRow from './TableRow';
import Tabs from './Tabs';
import TextField from './TextField';
import TimeClock from './TimeClock';
import ToggleButton from './ToggleButton';
import ToggleButtonGroup from './ToggleButtonGroup';
import Tooltip from './Tooltip';
import TouchRipple from './TouchRipple';
import TreeItem from './TreeItem';
import Typography from './Typography';
import YearCalendar from './YearCalendar';

export default function ComponentsOverrides(theme: Theme) {
  return _merge(
    Accordion(theme),
    AccordionDetails(theme),
    AccordionSummary(theme),
    Alert(theme),
    AlertTitle(),
    Autocomplete(theme),
    Avatar(theme),
    Badge(theme),
    Button(theme),
    ButtonBase(),
    ButtonGroup(),
    CardActions(theme),
    CardContent(),
    CardHeader(theme),
    Checkbox(theme),
    Chip(theme),
    DataGrid(theme),
    DatePicker(theme),
    DateTimePicker(),
    Dialog(),
    DialogContentText(theme),
    DialogTitle(),
    DialogContent(),
    DialogActions(),
    Divider(theme),
    DateTimePickerToolbar(),
    DropzoneBase(theme),
    Fab(theme),
    FormControlLabel(theme),
    FormLabel(theme),
    FormHelperText(theme),
    Grid(),
    IconButton(theme),
    ImageListItem(theme),
    ImageListItemBar(theme),
    InputBase(),
    InputLabel(theme),
    MenuItem(theme),
    LinearProgress(theme),
    Link(),
    List(),
    ListItemButton(theme),
    ListItemIcon(),
    LoadingButton(),
    MultiSectionDigitalClockSection(theme),
    OutlinedInput(theme),
    Pagination(),
    PaginationItem(theme),
    PickersCalendarHeader(theme),
    PickersDay(theme),
    PickersYear(theme),
    PickersLayout(),
    PickersToolbarText(theme),
    PickersArrowSwitcher(),
    DayCalendar(theme),
    DateCalendar(),
    DatePickerToolbar(),
    Card(),
    Popover(theme),
    Radio(theme),
    RichTreeView(theme),
    Skeleton(),
    Slider(theme),
    SvgIcon(),
    Switch(theme),
    Tab(theme),
    TableBody(theme),
    TableCell(theme),
    TableFooter(theme),
    TableHead(theme),
    TablePagination(),
    TableRow(),
    Tabs(),
    TimeClock(),
    TextField(theme),
    ToggleButton(theme),
    ToggleButtonGroup(theme),
    Tooltip(theme),
    TouchRipple(),
    TreeItem(theme),
    Typography(),
    YearCalendar(),
  );
}
