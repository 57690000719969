import { Box, Divider, Stack } from '@mui/material';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const headingMargins = {
  xs: 2,
  xl: 8,
};

const contentMargins = {
  xs: 4,
  xl: 12,
};

export interface LayoutContentProps {
  readOnlyBar: React.ReactNode;
  sideMenu: React.ReactNode;
  content: React.ReactNode;
}

export function LayoutContent({ readOnlyBar, sideMenu, content }: LayoutContentProps) {
  const location = useLocation();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [location.pathname]);

  return (
    <>
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 99,
          width: '100%',
        }}
      >
        {readOnlyBar}
      </Box>
      <Stack
        direction="column"
        sx={{
          height: '100%',
        }}
      >
        <Stack
          direction="row"
          sx={{
            flexGrow: 1,
            paddingLeft: '20px',
            paddingBottom: '20px',
            gap: '20px',
            maxHeight: '100%',
          }}
        >
          <Box
            sx={{
              flexGrow: 0,
              flexShrink: 0,
              overflowY: 'auto',
              maxHeight: '100%',
              paddingTop: '20px',
            }}
          >
            {sideMenu}
          </Box>
          <Box
            ref={ref}
            sx={{
              flexGrow: 1,
              maxHeight: '100%',
              overflowY: 'auto',
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                maxHeight: '100%',
                overflowY: 'visible',
              }}
            >
              {content}
            </Box>
          </Box>
        </Stack>
      </Stack>
    </>
  );
}

export interface SubpageLayoutContentProps {
  header: React.ReactNode;
  children: React.ReactNode;
}

export function SubpageLayoutContent({ header, children }: SubpageLayoutContentProps) {
  return (
    <Stack
      direction="column"
      sx={{
        height: '100%',
      }}
    >
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          paddingTop: '20px',
          marginLeft: 'auto',
          marginRight: 'auto',
          paddingLeft: headingMargins,
          paddingRight: headingMargins,
          backgroundColor: '#f0f2f5 !important',
          width: '100%',
          maxWidth: 1440,
          zIndex: 99,
        }}
      >
        {header}
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          marginTop: 5,
          marginLeft: contentMargins,
          marginRight: contentMargins,
          paddingBottom: 4,
        }}
      >
        {children}
      </Box>
    </Stack>
  );
}

export interface SubpageContentProps {
  children: React.ReactNode;
}

export function SubpageContent({ children }: SubpageContentProps) {
  return (
    <Stack
      direction="column"
      divider={
        <Stack
          direction="row"
          sx={{
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <Divider
            sx={{
              width: '100%',
              maxWidth: {
                xs: 680,
                md: 1150,
              },
            }}
          />
        </Stack>
      }
      sx={{
        gap: 6,
      }}
    >
      {children}
    </Stack>
  );
}
