export enum RichTextEditorFeature {
  TABLES,
  SUBSTITUTIONS,
  PREVIEW,
}

export type Substitutions = Record<string, object | string | number>;
export type FlatSubstitutions = Record<string, string>;

export type SuggestionItem = {
  id: string;
  label: string;
};

export type RichTextEditorContextT = {
  nonce?: string;
};
