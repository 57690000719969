export default function SvgIcon() {
  return {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          width: '20px',
          height: '20px',

          '&.MuiSelect-icon': {
            position: 'static',
            marginRight: '12px',
            marginLeft: '-4px',
          },
        },
      },
    },
  };
}
