import { Skeleton, Stack, useTheme } from '@mui/material';
import _range from 'lodash/range';

export interface SideMenuSkeletonProps {
  enabled?: boolean;
  rows?: number;
}

export function SideMenuSkeleton({ enabled = true, rows = 6 }: SideMenuSkeletonProps) {
  const theme = useTheme();

  return (
    <Stack
      direction="column"
      sx={{
        gap: theme.spacing(2),
      }}
    >
      {_range(0, rows).map(index => (
        <Skeleton
          key={`skeleton-${index}`}
          component="div"
          animation={enabled ? 'wave' : false}
          variant="rectangular"
          width={240}
          height={30}
        />
      ))}
    </Stack>
  );
}
