export default function DateCalendar() {
  return {
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          width: '280px',
          height: 'unset',
        },
      },
    },
  };
}
