/* eslint-disable @typescript-eslint/no-unused-vars */
import { Theme } from '@mui/material/styles';

export default function RichTreeView(theme: Theme) {
  return {
    MuiRichTreeView: {
      styleOverrides: {},
    },
  };
}
