export default function ButtonBase() {
  return {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          minHeight: 0,
        },
      },
    },
  };
}
