import { useContext } from 'react';
import { ServiceWorkerContext } from '@components/serviceworker';
import { ReloadPrompt } from './reloadprompt';

export function ReloadPromptWrapper() {
  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useContext(ServiceWorkerContext);

  return (
    <ReloadPrompt
      needRefresh={needRefresh}
      setNeedRefresh={setNeedRefresh}
      updateServiceWorker={updateServiceWorker}
    />
  );
}
